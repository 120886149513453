.slider-container {
    width: 100%;
    height: 100%;
}

.image-container {
    width: 100%;
    height: 400px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.slider-img {
    object-fit: contain;
}

.slick-dots {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    position: unset !important;
    margin-top: 20px !important;
}

.slick-dots li {
    width: 30px !important;
    height: 30px !important;
    list-style: none;
}

.slick-dots li button {
    border: none;
    background: transparent;
    padding: 0;
}

.slick-dots li img {
    border-radius: 4px;
    cursor: pointer;
}
