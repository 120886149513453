.user-mang-autocomplete-search {
    width: 300px;
}

.user-mang-status-header-search__control {
    height: 50px;
    min-height: 50px !important;
    background-color: #eeeeee;
    font-size: 16px;
}

.user-mang-status-header-search__placeholder {
    color: #000000 !important;
}
.user-mang-status-header-search__indicator {
    color: #74788d !important;
    padding-left: 0px !important;
    padding-right: 2px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.user-mang-status-header-search__indicator {
    color: #000000 !important;
}
.user-mang-status-header-search__indicators {
    min-width: inherit;
}
.user-mang-status-header-search__indicator-separator {
    display: none;
}

.user-mang-data-container {
    box-shadow: 0px 12px 18px #0000000d;
    border-radius: 0px 0px 4px 4px;
    margin: 0 6px 0 6px;
}

.user-mang-modal-label {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    justify-content: flex-end;
    font: normal normal 600 14px/21px Poppins !important;
    color: #000000 !important;
}

.user-mang-modal-input {
    display: flex;
    font: normal normal medium 600 14px/21px Poppins;
    opacity: 1;
}

.user-mang-form-handler {
    margin-right: 20px;
}

.user-mang-modal-del-btn {
    margin-left: 10px;
    width: 200px;
}
.user-mang-modal-cancel-btn {
    background-color: white !important;
    color: #296f9e !important;
    border-color: #296f9e !important;
}

.user-add-edit-card-container {
    width: 80%;
    margin: 0 10% 3% 10%;
}

.button-width-container {
    margin-left: 15px;
    width: 91%;
}

.user-add-edit-card-container {
    .user-validation-handler {
        margin-bottom: 0 !important;
    }
}

.status-header {
    text-align: center;
    min-width: 180px;
}
.admin-usermanag-status-toggle {
    margin: auto;
    width: fit-content;
}
.admin-usermanag-status-switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 10px;
    vertical-align: middle;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
}

.admin-usermanag-status-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.admin-usermanag-status-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2980b9;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.admin-usermanag-status-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 0px;
    bottom: -4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 2px solid #2980b9;
}

input:checked + .admin-usermanag-status-slider {
    background-color: #2980b9;
}

input:focus + .admin-usermanag-status-slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .admin-usermanag-status-slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
}

/* Rounded sliders */
.admin-usermanag-status-slider.round {
    border-radius: 34px;
}

.admin-usermanag-status-slider.round:before {
    border-radius: 50%;
}
.password-change-icon {
    color: #007d08;
    font-size: 24px;
    vertical-align: bottom;
}
