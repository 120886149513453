.h1,
h1 {
    font-size: em(32);
}
.h2,
h2 {
    font-size: em(23);
}
.h3,
h3 {
    font-size: em(20);
}
.h4,
h4 {
    font-size: em(18);
}
.h5,
h5 {
    font-size: em(16);
}
.h6,
h6 {
    font-size: em(13);
}
.fw-600 {
    font-weight: 600;
}
.fw-500 {
    font-weight: 500;
}
.clr-dark-gray-3 {
    color: $gray-dark-3;
}
.clr-gray-dark {
    color: $gray-dark;
}
.clr-purple {
    color: $purple;
}
.clr-blue {
    color: $blue;
}
.clr-blue-2 {
    color: $blue-2;
}
.clr-gray-light {
    color: $gray-light;
}
.clr-gray-light-2 {
    color: $gray-light-2;
}
.clr-white {
    color: $white;
}
.clr-black {
    color: $black;
}
.clr-red {
    color: $red;
}
.bg-clr-red {
    background-color: $red;
}
.bg-clr-green {
    background-color: $green;
}
.bg-clr-yellow {
    background-color: $yellow;
}
.bg-clr-blue {
    background-color: $blue;
}
.bg-clr-black {
    background-color: $black;
}
.bg-clr-pink {
    background-color: $pink;
}
.bg-clr-gray-light-6 {
    background-color: $gray-light-6;
}
.bg-clr-body {
    background-color: $body-bg;
}
.gt-main-heading {
    font-size: em(30);
    font-family: "Poppins";
    font-weight: 600;
}
.gt-w-30 {
    width: 30%;
}
