@media (max-width: 958px) {
    .admin-location > .al-navtabs-main > .al-nav-tabs {
        display: flex;
        flex-direction: column;
        .al-nav-item {
            flex-direction: column;
        }
        .bg-navitems-primary {
            margin-bottom: 20px;
            padding-bottom: 7px !important;
        }
    }
}
