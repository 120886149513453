.warehouse-nav-tabs-global  > div > .nav-tabs .nav-item .nav-link,
.warehouse-nav-tabs-global  > div > .nav-tabs .nav-link.active
{
  border-radius: 4px;
  color: white;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 5px 30px;
  border: none;
  justify-content: center;
  // text-transform: capitalize;
}
.warehouse-nav-tabs-global   >div >  .nav-tabs .nav-item .nav-link,
.warehouse-nav-tabs-global   >div >  .nav-tabs .nav-link.active{
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  z-index: 100;
}
  .warehouse-nav-tabs-global   > div > .nav-tabs .nav-item .nav-link {
    background: #CED4DA;
    opacity: 1;
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
    color: #4E4E4E;
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    // width: 180px;  
    width: 160px;
    height: 40px;
    padding: 0px;
  }
  .warehouse-nav-tabs-global   > div >  .nav-tabs .nav-link.active{
    color: #1374D5;
    position: relative;
    background: #CED4DA;
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    margin-right: 2px;
    margin-left: 2px;
    font-weight: 600;
  }
  .warehouse-nav-tabs-global   > div >  .nav-tabs .nav-link.active{
    margin-right: 0px;
    margin-left: 0px;
  }
  .warehouse-nav-tabs-global   >div >  .nav-tabs .nav-link:first-child
  {
    margin-right: 2px;
  }
  .warehouse-nav-tabs-global   >div >  .nav-tabs .nav-link.active:last-child {
    margin-right: 2px;
  }