.skeleton-input {
    width: 100%;
    background: #e0e0e0;
    height: 25px;
    border-radius: 4px;
    margin-bottom: 20px;
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-button {
    width: 25%;
    height: 38px;
    background: #e0e0e0;
    border-radius: 4px;
    margin-top: 20px;
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}
