.wh-im-vd-con {
  width: 220px !important;
}
.wh-im-header-typo{
  font-size: 14px;
  color: #74788D;
}
.wh-im-vd-con > div > .add-new-req__control
{
  height: 40px;
  min-height: 40px !important;
}
.table-body-border  table tbody tr td
{
  border-right: 1px solid #CDCDCD;
  border-bottom: 1px solid #cdcdcd;
}
.table-body-border  table tbody tr td:last-child
{
  border-right: none;
}
.table-body-border .w-20 {
  width: 20%;
}
.table-body-border .w-80 {
  width: 80%;
}