
.gt-category-listing {

    .category-image-wrapper{
        @include shadow(0px 0px 20px $shadow-clr);
        @include radius(8px);
        min-height: em(85);
        max-height: em(85);
        min-width: em(85);
        max-width: em(85);
        display: flex;
        margin: em(5);
        img {
            max-width: 70%;
            margin: auto;
            width: 70%;
        }
}
}
