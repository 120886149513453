.base-feature-table {
    min-width: 1005px;
}

.base-feature-category-table {
    min-width: 728px;
}

@media (max-width: 1200px) {
    .trailer-container {
        .header-margin-container {
            margin: 4px;
        }
    }
}

@media (max-width: 1024px) {
    .trailer-container {
        .headerButtonResponsive {
            width: 200px;
        }
    }

    .trailer-container {
        .header-search-responsiveness > input {
            height: 40px;
        }
    }

    .vendor-search-input-only {
        height: 100%;
    }
}

@media (max-width: 720px) {
    .trailer-container {
        .admin-inventory-management-general-inventory-responsiveness {
            flex-direction: column;
            margin: 8px 0;
        }
        .trailer-btn-container {
            .headerButtonResponsive {
                margin-top: 10px;
                width: 100%;
            }
        }
    }
}
