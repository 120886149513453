.gt-sidebar {
  display: flex;
  flex-direction: column;
  &.vertical-menu {
    // display: none;

    .navbar-brand-box {
      display: flex !important;
      justify-content: center !important;
      background-color: inherit;
      min-height: em(70);
      .logo-light {
        line-height: em(70);
        .logo-sm {
          img {
            padding-left: 10px;
            max-width: em(136);
            max-height: em(38);
          }
        }
        .logo-lg {
          img {
            max-width: em(136);
            max-height: em(38);
          }
        }
      }
    }
    .gt-side-menu {
      margin-top: em(52);
      ul#side-menu {
        li {
          a {
            display: flex;
            align-items: center;
            font-size: em(13);
            font-family: "Poppins";
            color: $white;
            img {
              margin-inline-end: em(11.06);
            }
          }
          &:hover {
            > a {
              background: lighten($casita-blue, 2%);
              // background: none;
              color: $sidebar-dark-menu-item-hover-color;
              i {
                color: $sidebar-dark-menu-item-hover-color;
              }
            }

            > ul {
              a {
                color: $sidebar-dark-menu-sub-item-color;
                &:hover {
                  color: $sidebar-dark-menu-item-hover-color;
                }
              }
            }
          }
          &.mm-active {
            > {
              a {
                position: relative;
                &::before {
                  content: "";
                  height: 100%;
                  width: 100%;
                  //   position: absolute;
                  z-index: -1;
                  //   background: $casita-blue;
                  //   mix-blend-mode: soft-light;
                  //   left: 0;
                  top: -50;
                  //   opacity: 0.23;
                  //   //   border radius here
                  //   border-radius: 50px 0 0 50px;
                  right: 0;

                  background-color: $body-bg;

                  //   background-color: white;
                  position: absolute;
                }
                img {
                  filter: brightness(0) saturate(100%);
                }
                span {
                  color: black;

                  font-weight: 600;
                }
              }
            }
          }
        }
        & + .mm-active {
          &::before {
            content: "\F0142";
          }
        }

        & + .mm-active {
          &::after {
            content: "\F0142";
          }
        }
      }
    }
    @media screen and (max-width: 991px) {
      top: em(70);
    }
  }
}
