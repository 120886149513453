.page-limit-typo {
    color: #495057;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
}
.page-limit-select {
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    color: #495057;
    /* font-family: 'Poppins'; */
    /* font-style: normal; */
    font-weight: 400;
    font-size: 11.37px;
    line-height: 17px;
}