.finance-inventory-management-table,
.finance-im-wh-chemicals {
  min-width: 501px;
}

.finance-inventory-management-warehouse-tool-table {
  min-width: 554px;
}
.finance-inventory-management-department-general-inventory-table {
  min-width: 802px;
}
.finance-inventory-management-department-tool-inventory-table {
  min-width: 917px;
}
.finance-vertical-scroll-im {
  max-height: 50vh;
}
.stock-status{
  .stock-status-label {
  width: 15.66666%;
}
}
.pr-table-header {
  padding-right: 0.5rem;
}
@media (max-width: 1200px) {
  .stock-status{
    .stock-status-label {
    width: 16.66666%;
  }
  }
}
@media (max-width: 992px) {
  .gt-btn-grad-primary {
    height: 40px;
  }
  .warehouse-nav-tabs > div > .nav-tabs .nav-item .nav-link-finance-ic1,
  .warehouse-nav-tabs > div > .nav-tabs .nav-item .nav-link-finance-ic2,
  .warehouse-nav-tabs > div > .nav-tabs .nav-item .nav-link-finance-ic3 {
    width: 135px !important;
    height: 35px !important;
    font-size: 14px !important;
  }
  .gi-top-heading {
    font-size: 20px;
  }
  .ft-table-header {
    font-size: 16px !important;
  }
  .status-header-search-admin__control {
    min-height: 40px !important;
  }
  .fm-location-search-box .vendor-table-search-inner-class > input {
    height: 40px !important;
    width: 190px !important;
  }
  .stock-status{
    .stock-status-label {
    width: 17%;
  }
  }
  .stock-status{
    .stock-status-data {
    width: 83%;
  }
  }
}

@media (max-width: 720px) {
  .fm-location-search-box {
    margin-bottom: 0.5rem !important;
    width: 100%;
  }
  .fm-location-search-box .vendor-table-search-inner-class > input {
    width: 88% !important;
  }
  .wh-im-dpgi-status {
    width: 100% !important;
  }
  .department-gi-top-autocomplete {
    margin-bottom: 0.5rem;
  }
  .finance-item-listing-header {
    .wh-im-dpgi-status {
      width: 100% !important;
      height: inherit;
      padding-top: 6.5px;
      padding-bottom: 6.5px;
    }
  }
  .pr-table-header {
    padding-right: 0px ;
  }
}

@media (max-width: 650px) {
  .warehouse-nav-tabs > div > .nav-tabs .nav-item .nav-link-finance-ic2,
  .warehouse-nav-tabs > div > .nav-tabs .nav-item .nav-link-finance-ic3 {
    width: 110px !important;
    height: 35px !important;
  }
}
