.ad-user-mangement-table {
    min-width: 692px;
}

@media (max-width: 1024px) {
    .ad-header-btn-responsive {
        width: 200px;
    }
    .location-search-box .admin-user-mangement-search-responsiveness > input {
        height: 40px !important;
    }
}
@media (max-width: 992px) {
    .ad-user-mangement {
        flex-direction: column;
    }
    .ad-user-management-inner {
        margin-top: 10px;
    }
}
@media (max-width: 720px) {
    .ad-search-box-container {
        width: 50%;
        margin-bottom: 0;
    }
    .ad-header-btn-responsive {
        width: 200px;
        height: 40px;
    }
    .location-search-box .admin-user-mangement-search-responsiveness > input {
        width: 85%;
    }
}
@media (max-width: 678px) {
    .ad-user-management-inner {
        flex-direction: column-reverse;
    }
    .ad-search-box-container {
        width: 100%;
    }
    .ad-header-btn-responsive {
        width: 100%;
        margin-top: 10px;
    }
    .location-search-box .admin-user-mangement-search-responsiveness > input {
        width: 92%;
    }
}
