// style to add here
.custom-btn-close-popup {
   border: none;
   background: transparent;
}

.custom-close-btn-popup {
   border: none;
   background: transparent;
   position: absolute;
   top: 10px;
   right: 10px;
   z-index: 1050;
}

// .custom-close-btn-popup img {
//     width: 20px;
//     height: 20px;
// }

.modal-backdrop.show {
   opacity: 0.3 !important;
}
