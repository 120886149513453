$gradient-bg: transparent linear-gradient(277deg, #FF416C 0%, #FF4B2B 100%) 0% 0% no-repeat padding-box;
.casita-container-wrapper{
    & .business-details-header{
        min-height: em(218);
        color: white;
        background: $gradient-bg;
        box-shadow: 0px 3px 30px $shadow-clr-2;
        padding: em(20);
        & .header-section{
            border-right: 1px solid $gray-light-8;
            padding: em(25) em(5);
        }
        & .header-avatar{
            bottom:em(-118);
            left:em(60);
            max-width: fit-content;
            max-width: -moz-fit-content;
        }
    }
    & .gt-btn-gradient{
        border-width: 0;
        background: $gradient-bg;
        color: $white !important;
        &:hover {
            color: $white !important;
        }
    }
    & .casita-container-wrapper-details-body{
        .gt-data-table{
            max-height: em(400);
            table{
               
                thead{
                    th{
                        border-top: none;
                       
                    }
                }
                
            }
            &::-webkit-scrollbar {
                width: em(6);
                height: em(6);
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
                background: $white-2;
            }
        
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $gradient-bg;
            }
        
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
               
            }
            & .status-toggle {
                font-size: 16px;
                & .form-check-input{
                    width: 51px;
                    height: 27px;
                    
                    &:checked{
                        background-color: $red-2;
                        border-color: $red-2;
                    }
                    &:not(:checked){
                        background-color: $green-3;
                        border-color: $green-3;
                        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
                    }
                }
            }
        }
        & .profile-picture-wrapper{
            height: em(72);
            width: em(72);
            img{
                height: em(72);
                width: em(72);
                object-fit: contain;
            }
        }
    }
}

@media (max-width : 768px) {
    .casita-container-wrapper{
        & .business-details-header{
            & .header-section{
                border-right: 0;
            }
        }
    }
}

.table-main-heading{
    font-size: 16px;
}