.gt-color-palette-wrap {
    .gt-color-palette {
        position: relative;
        border: 3px solid $white;
        @include shadow(0px 0px 20px $shadow-clr);
        @include radius(50%);
        @include transition(all 0.5s ease-in-out);
        cursor: pointer;
        &-red {
            background-color: $pdt-clr-red;
        }
        &-blue {
            background-color: $pdt-clr-blue;
        }
        &-green {
            background-color: $pdt-clr-green;
        }
        &-pink {
            background-color: $pdt-clr-pink;
        }
        &-yellow {
            background-color: $pdt-clr-yellow;
        }
        &-black {
            background-color: $pdt-clr-black;
        }
        &-red {
            background-color: $pdt-clr-red;
        }
        &-yellow {
            background-color: $pdt-clr-yellow;
        }
        &-black {
            background-color: $pdt-clr-black;
        }
        &-lg{
            width: em(74);
            height: em(74);
            &:not(:last-child) {
                margin-right: em(40);
            }
        }
        &-md {
            width: em(39);
            height: em(39);
            &:not(:last-child) {
                margin-right: em(20);
            }
        }
        &-sm {
            width: em(30);
            height: em(30);
            &:not(:last-child) {
                margin-right: em(20);
            }
        }
        &.checked {
            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                content: "\e9da";
                font-family: boxicons !important;
                color: $white;
                transform: translate(-50%, -50%);
                font-size: em(20);
            }
        }
        
    }
}
