.gt-reports-details{
    & .card-layout{
        box-shadow: 0px 0px 20px $shadow-clr;
        border-radius: 8px;
    }
    & .message-box{
        max-height: em(400);
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: em(8);
            height: em(8);
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: $white-2;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $gradient-bg;
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
           
        }
    }
}