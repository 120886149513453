.location-data-container {
   box-shadow: 0px 12px 18px #0000000d;
   border-radius: 0px 0px 4px 4px;
   margin: 0 6px 0 6px;
}
.location-icon-container {
   width: 60px;
}
h1 {
   color: #000000;
   font-weight: 500;
}

.modal-header-container {
   margin-bottom: 20px;
}

.modal-header-container > h5 {
   text-align: center;
   font-size: 1.5rem;
   font-weight: 600;
   letter-spacing: 0px;
   color: #000000;
   opacity: 1;
}

.modal-header-container > button {
   border: none;
}

.location-form-handler {
   margin-right: 20px;
}

.input-text-container {
   margin-left: 10px;
}

.location-modal-label {
   display: flex;
   align-items: center;
   margin-bottom: 0 !important;
   justify-content: flex-end;
   font: normal normal 600 14px/21px Poppins !important;
   color: #000000 !important;
}
.location-modal-input {
   display: flex;
   opacity: 1;
}
.location-modal-del-btn {
   margin-left: 10px;
   width: 200px;
}
.location-modal-cancel-btn {
   width: 200px;
   background-color: white !important;
   color: #296f9e !important;
   border-color: #296f9e !important;
}
.location-add-edit-card-container {
   width: 80%;
}

.admin-location > .al-navtabs-main {
   background: #ffffff 0% 0% no-repeat padding-box;
   box-shadow: 0px 12px 18px #0000000d;
   margin-top: 1.2em;
   padding: 13px 0px;
   padding-bottom: 0px;
}

.admin-location > .al-navtabs-main > .al-nav-tabs > .al-nav-item .al-nav-link {
   font-size: 19px;
   cursor: pointer;
   font-weight: 500;
}
.admin-location
   > .al-navtabs-main
   > .al-nav-tabs
   > .al-nav-item
   .bg-navitems-secondary {
   padding-bottom: 16px;
   cursor: pointer;
}
.admin-location
   > .al-navtabs-main
   > .al-nav-tabs
   > .al-nav-item
   .bg-navitems-primary {
   color: #1374d5;
   padding-bottom: 14px;
   border-bottom: 4px solid #297eb6;
   cursor: pointer;
}
.admin-location
   > .al-navtabs-main
   > .al-nav-tabs
   > .al-nav-item
   .bg-navitems-primary
   > a {
   color: #1374d5;
}
.admin-location
   > .al-navtabs-main
   > .al-nav-tabs
   > .al-nav-item
   .bg-navitems-secondary
   > a {
   color: #c4c4c4;
}

.location-form-handler {
   .location-add-edit-fg {
      margin-bottom: 0px !important;
   }
}

.add-new-req__control {
   height: 30px;
   min-height: 30px !important;
}
.add-new-req__container {
   width: 70%;
}

.add-new-req__placeholder {
   font-family: "Poppins";
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
   color: #ced4da;
}
.add-new-req__indicator,
.status-header-search__indicator {
   color: #74788d !important;
   padding-left: 0px !important;
   padding-right: 2px !important;
   padding-top: 0px !important;
   padding-bottom: 0px !important;
}

.add-new-req__indicators,
.status-header-search__indicators {
   min-width: inherit;
}
.add-new-req__indicator-separator,
.status-header-search__indicator-separator {
   display: none;
}

//******

[data-container] {
   position: relative;
}

[data-container]:hover::before {
   content: attr(data-container);

   position: absolute;
   bottom: 20px;
   padding: 10px;
   background: #f5f4f4;
   color: #030303;
}

@media (min-width: 1400px) {
   .add-new-req__value-container {
      padding: 0px 8px !important;
   }
}
@media (max-width: 1100px) {
   .admin-location
      > .al-navtabs-main
      > .al-nav-tabs
      > .al-nav-item
      .bg-navitems-primary
      > a,
   .admin-location
      > .al-navtabs-main
      > .al-nav-tabs
      > .al-nav-item
      .bg-navitems-secondary
      > a {
      font-size: 15px;
   }
   .admin-location
      > .al-navtabs-main
      > .al-nav-tabs
      > .al-nav-item
      .bg-navitems-primary
      > img,
   .admin-location
      > .al-navtabs-main
      > .al-nav-tabs
      > .al-nav-item
      .bg-navitems-secondary
      > img {
      width: 25px;
   }
}
.inventory-column-btns {
   width: fit-content !important;
}
.add-req-inv-con {
   width: 90%;
   margin-right: auto;
   margin-left: auto;
}
.add-req-inv-table {
   table-layout: fixed;
   text-align: center;
}
.add-req-inv-con table tbody tr td:first-child,
.add-req-inv-con table thead tr th:first-child {
   padding-left: 0rem;
}
.add-new-req__control {
   height: 30px;
   min-height: 30px !important;
}
.add-new-req__container {
   width: 70%;
}

.add-new-req__placeholder {
   font-family: "Poppins";
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
   color: #ced4da;
}
.add-new-req__indicator,
.status-header-search__indicator {
   color: #74788d !important;
   padding-left: 0px !important;
   padding-right: 2px !important;
   padding-top: 0px !important;
   padding-bottom: 0px !important;
}

.add-new-req__indicators,
.status-header-search__indicators {
   min-width: inherit;
}
.add-new-req__indicator-separator,
.status-header-search__indicator-separator {
   display: none;
}

.add-new-req-select-con {
   width: 250px;
   margin-right: auto;
   margin-left: auto;
}

.view-detail-modal-delete {
   cursor: pointer;
}

.inventory-description {
   background: #f5f5f5;
   border-width: 1px 0px;
   border-style: solid;
   border-color: #cdcdcd;
   height: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.inventory-table {
   min-width: 1000px;
}
.add-new-request-table {
   min-width: 550px;
}
@media (min-width: 1400px) {
   .add-new-req__value-container {
      padding: 0px 8px !important;
   }
}
