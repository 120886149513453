.gt-card {
    &-product {
        @include shadow(none);
        background-color: transparent;
        width: em(280);
        .card-body {
            padding: 0;
            max-width: em(281);
            width: 100%;
        }
        &-inner {
            width: em(280);
            background-color: $white;
            padding: em(14);
            @include shadow(0px 3px 30px $shadow-clr);
            @include radius(8px);
            min-height: em(363);
            position: relative;
            overflow: hidden;
            z-index: 1;
            &::before {
                content: "";
                background: transparent linear-gradient(180deg, $white 0%, #ff4268 46%, #ff4b2b 100%) 0% 0% no-repeat
                    padding-box;
                position: absolute;
                z-index: -1;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 60%;
            }
            &::after {
                content: "";
                position: absolute;
                top: -110%;
                left: -210%;
                width: 200%;
                height: 200%;
                opacity: 0;
                z-index: -1;
                transform: rotate(30deg);
                background: rgba(255, 255, 255, 0.13);
                background: linear-gradient(
                    to right,
                    rgba(255, 255, 255, 0.13) 0%,
                    rgba(255, 255, 255, 0.13) 77%,
                    rgba(255, 255, 255, 0.5) 92%,
                    rgba(255, 255, 255, 0) 100%
                );
            }
            &:hover {
                &::after {
                    opacity: 1;
                    top: -30%;
                    left: -30%;
                    transition-property: left, top, opacity;
                    transition-duration: 0.7s, 0.7s, 0.15s;
                    transition-timing-function: ease;
                }
            }
            & .gt-card-product-title{
                min-height: em(80);
            }
        }
        .gt-pdt-card-img-wrapper{
            min-width: em(250);
            min-height: em(240);
            padding-top: em(15);
            padding-bottom: em(15);
        }
        .gt-pdt-card-img {
            max-width: 70%;
            margin: auto;
            display: flex;
            justify-content: center;
            img{
                max-height: em(170);
                max-width: em(170);
            }
        }
        .gt-pdt-card-actions {
            max-width: calc(100% - #{em(120)});
            margin-inline: auto;
        }
    }
    &-category {
        @include shadow(none);
        .card-body {
            padding: 0;
        }
        &-wrap {
            @include shadow(0px 3px 30px $shadow-clr);
            @include radius(8px);
            max-width: em(172);
            min-height: em(172);
            width: 100%;
            padding: em(9);
            cursor: pointer;
        }
        &-check {
            border: 1px solid $white;
        }
        &-img {
            margin-bottom: em(8);
            min-height: em(109);
            display: flex;
            flex-direction: column;
            justify-content: center;
            svg {
                margin: auto;
            }
            img {
                height: em(100);
                width: em(100);
            }
        }
    }
}
