.equipment-table {
   min-width: 813px;
}

.equipment-category-table {
   min-width: 692px;
}
.height-style {
   max-height: 60vh;
   overflow-y: scroll;
}
