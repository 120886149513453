.setting-icon {
  padding: 10px;
}

.label-Container {
  font-size: 13px !important;
  padding-left: 50px !important;
  font-weight: 600;
}
.text-input {
  display: flex;
  justify-content: center;
}

.finance-setting-bottom-button-container {
  padding-left: 30px !important;
  margin-top: 70px;
}

.table-bottom-btn {
  height: 34px;
  width: 300px;
}
.input-validation-container {
  margin-bottom: 10px;
}

.form-container {
  .user-validation-handler {
    margin-bottom: 0 !important;
  }
}

.loader-handle {
  padding-top: 100px;
  padding-bottom: 50px;
}

.setting-header > h1 {
  color: #000000;
}

.form-container {
  margin-top: 50px;
}
.toggle-ip > input{
  width: 0px;
}

@media (max-width: 668px) {
  .finance-setting-bottom-button-container{
    .setting-finance-btn {
      width: 100% !important;
    }
  }
}
/* // Extra small devices (portrait phones, less than 576px) */
@media screen and (max-width: 622px) {
  .label-Container {
    text-align: left !important;
    padding-left: 15px !important;
  }
  .table-bottom-btn {
    min-width: 0px !important;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 623px) and (max-width: 767.98px) {
  .table-bottom-btn {
    min-width: 0px !important;
  }

  .table-bottom-btn {
    min-width: 0px !important;
  }
  .loader-handle {
    padding-left: 0;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 1071px) and (max-width: 1199.98px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

@media (min-width: 623px) and (max-width: 1071px) {
  .label-Container {
    font-size: 13px !important;
    padding-left: 25px !important;
    font-weight: 600;
  }
  .finance-setting-bottom-button-container {
    padding-left: 15px !important;
  }

  .loader-handle {
    padding-top: 100px;
    padding-bottom: 50px;
    padding-left: 0%;
  }
}
