.gt-breadcrumb {
    .breadcrumb-item {
        > {
            a {
                font-size: em(13);
                color: $gray-light;
            }
        }
        & + .breadcrumb-item {
            &::before {
                content: "\F0142";
            }
        }
    }
}
