.status-switch-container {
    display: flex;
    align-items: center;
}

.status-switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 10px;
    margin: 0 10px;
}

.status-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.status-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2980b9;
    transition: 0.4s;
}

.status-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 0px;
    bottom: -4px;
    background-color: white;
    transition: 0.4s;
    border: 2px solid #2980b9;
}

input:checked + .status-slider {
    background-color: #2980b9;
}

input:focus + .status-slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .status-slider:before {
    transform: translateX(12px);
}

.status-slider.round {
    border-radius: 34px;
}

.status-slider.round:before {
    border-radius: 50%;
}

.password-change-icon {
    color: #007d08;
    font-size: 24px;
    vertical-align: bottom;
}
