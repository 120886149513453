.gt-qty-counter {
    display: flex;
    border: 0.3px solid $bdr-clr-6;
    width: min-content;
    margin-right: auto;
    @include radius(4px);
    .gt-qty-input {
        font-weight: 600;
        color: $black;
        font-size: em(15);
        background-color: transparent;
        min-width: em(45);
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    button {
        background-color: transparent;
        border: none;
        color: $qty-btn;
        min-width: em(36);
        &.gt-qty-count-plus {
            border-right: 1px solid $bdr-clr-6;
        }
        &.gt-qty-count-minus {
            border-left: 1px solid $bdr-clr-6;
        }
    }
}
