// ===================================

@media (max-width: 1023px) {
  .header {
    font-size: 30px !important;
  }
  .signin-input {
    width: 90%;
  }
  .sign-in-forget-password {
    font-size: 13px !important;
  }

  .form-label {
    font-size: 15px !important;
    line-height: 20px;
  }
  .form-check {
    width: 50%;
  }
}

@media (max-width: 992px) {
  .header {
    font-size: 25px !important;
  }
  .form-label {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .signin-input {
    width: 100%;
  }
  .sign-in-btn > button {
    width: 50%;
    height: 50px;
    font-size: 18px !important;
  }
}

@media (max-width: 767px) {
  .form-check {
    width: 50% !important;
  }
  .sign-in-forget-password {
    font-size: 13px !important;
  }
  .left-img-inner-container > h5 {
    font-size: 30px;
  }
}

@media (max-width: 575px) {
  .left-img-inner-container > h5 {
    font-size: 25px;
  }
  .sign-in-btn > button {
    min-width: 50% !important;
  }
  .form-check {
    width: 100% !important;
  }
  .admin-fp-typo{
    text-align: center;
  }
}

@media (max-width: 575px) {
  .wrap-login100 {
    width: 100% !important;
    justify-content: center !important;
  }

  .signin-input {
    width: 100%;
  }
  .sign-in-btn > button {
    width: 100% !important;
    text-align: center;
  }
  .form-classed {
    width: 70% !important;
  }
  .forget-verify-container {
    width: 100%;
    min-height: 100vh;
  }
  .sign-in-forget-password {
    font-size: 16px !important;
  }
}
