.ad-inventory-request-table {
  min-width: 572px;
}

.ad-pullback-inventory-request-table {
  min-width: 690px;
}

.ad-scrapreturn-inventory-request-table {
  min-width: 806px;
}

.ad-scrapreturn-department-inventory-request-table {
  min-width: 692px;
}

.ad-depart-inventory-request-table {
  min-width: 1140px;
}

.ad-inventory-request-pull-back-modal-table {
  min-width: 871px;
}
.ad-scrapreturn-department-inventory-request-table {
  min-width: 688px;
}

.verticalScrollIR {
  max-height: 50vh;
}

@media (max-width: 1100px) {
  .admin-location > .al-navtabs-main {
    padding: 13px 0px;
    padding-bottom: 0px;
  }
  .min-250-w {
    min-width: 200px;
  }

  .admin-location
    > .al-navtabs-main
    > .al-nav-tabs
    > .al-nav-item
    .bg-navitems-primary
    > a,
  .admin-location
    > .al-navtabs-main
    > .al-nav-tabs
    > .al-nav-item
    .bg-navitems-secondary
    > a {
    font-size: 13px !important;
  }

  .admin-location
    > .al-navtabs-main
    > .al-nav-tabs
    > .al-nav-item
    .bg-navitems-primary
    > img,
  .admin-location
    > .al-navtabs-main
    > .al-nav-tabs
    > .al-nav-item
    .bg-navitems-secondary
    > img {
    width: 20px !important;
  }
}

//*****
@media (max-width: 992px) {
  .min-250-w {
    min-width: 200px;
  }

  .inventory-request-search-responsiveness {
    .vendor-table-search-inner-class > input {
      height: 50px !important;
      width: 200px !important;
    }
  }

  .admin-inventory-request-search-box-responsive {
    .input-search {
      height: 40px;
    }
  }

  .admin-location
    > .al-navtabs-main
    > .al-nav-tabs
    > .al-nav-item
    .bg-navitems-primary
    > a,
  .admin-location
    > .al-navtabs-main
    > .al-nav-tabs
    > .al-nav-item
    .bg-navitems-secondary
    > a {
    font-size: 12px !important;
  }

  .admin-location
    > .al-navtabs-main
    > .al-nav-tabs
    > .al-nav-item
    .bg-navitems-primary
    > img,
  .admin-location
    > .al-navtabs-main
    > .al-nav-tabs
    > .al-nav-item
    .bg-navitems-secondary
    > img {
    width: 20px !important;
  }
}

@media (max-width: 940px) {
  .admin-inventory-request-form-fields {
    flex-direction: column-reverse;
  }
  .ad-search-container {
    margin-bottom: 0.5rem;
    width: 100%;
  }
  .admin-inventory-request-autocomplete {
    width: 100%;
  }
  .min-250-w {
    width: 50%;
  }
  .admin-inventory-request-form-fields
    .ad-search-container
    .vendor-table-search-inner-class
    > input {
    height: 40px;
    width: 95% !important;
  }
}

@media (max-width: 838px) {
  .admin-location > .al-navtabs-main > .al-nav-tabs {
    display: flex;
    flex-direction: column;
    .al-nav-item {
      flex-direction: column;
    }
    .bg-navitems-primary {
      margin-bottom: 20px;
      padding-bottom: 7px !important;
    }
  }
  .admin-inventory-request-form-fields
    .ad-search-container
    .vendor-table-search-inner-class
    > input {
    width: 93% !important;
  }
}

@media (max-width: 660px) {
  .admin-inventory-request-autocomplete {
    flex-direction: column;
  }
  .min-250-w {
    width: 100%;
  }
  .margin-top-handle {
    margin-top: 0.5rem;
  }
  .admin-inventory-request-autocomplete {
    .margin-right-handle {
      margin-right: 0rem !important;
    }
  }
}
