.signin-inputs{
    padding: 10px;
    height: 56px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.sign-in-remember {
    text-decoration-line: underline;
    color: #111111 !important;
}