.slick-prev,
.slick-next {
    color: black;
    background-color: white !important;
}

.slick-prev:before,
.slick-next:before {
    color: black;
    background-color: white;
}
