.gt-product-select-dropdown {
    select {
        border: 0.3px solid $bdr-clr-5;
        @include radius(4px);
        font-size: em(14);
        color: $pdt-select-dropdown;
        &:focus {
            @include shadow(none);
            border-color: $bdr-clr-5;
        }
    }
}
