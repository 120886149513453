.refresh-container {
    width: 35px;
    margin-top: 5px;
    margin-left: 5px;
    padding: 0 !important;
}

.refresh-icon {
    margin-top: 2px;
}
