.image-placeholder-container {
   
    .image-placeholder{
        border: 6px solid $gray-light-8;
        border-radius: 50%;
       &-md{
        width: em(200);
        height: em(200);
       }
       &-lg{
        width: em(285);
        height: em(285);
       }
    }
}