.dashboard-container {
    margin-top: 10px;
    margin-left: 5px;
}
.bg-stock-red {
    // color: #ee1d52;
    color: #ff2400;
}
.bg-stock-yellow {
    // color: #f1b44c;
    color: #edd213;
}
.bg-stock-green {
    // color: #34c38f;
    color: #4cbb17;
}

.bg-red {
    // background: #eeacbd;
    background: #ff2400;
    color: white;
}
.bg-yellow {
    // background: #f5cd87;
    background: #edd213;
    color: white;
}
.bg-green {
    // background: #90f8d2;
    background: #4cbb17;
    color: white;
}

.card-data-color {
    color: #1374d5;
}

.dashboard-data-container {
    box-shadow: 0px 12px 18px #0000000d;
    border-radius: 0px 0px 4px 4px;
    background-color: #fff;
}

.wh-mang-global-quantity {
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 20px;
    // width: 110px;
    margin: auto;
}

.bg-header-color > table > thead {
    background: #e1e1e1;
}

.dh-srap-return-header {
    font-size: 16px;
}

.dh-scrap-return-bottom-btn {
    height: 35px;
    width: 150px;
}

.text-elipse {
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-title-modal {
    font-size: 1.5rem;
    font-weight: 600;
}
