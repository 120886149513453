.gt-dashboard {
    .gt-welcome-comp {
    }

    .gt-top-business {
        height: 95%;
    }
    .gt-recent-notifications-feed{
        height: 95%;
    }
    .gt-total-earnings-widget{
        height: 95%;
    }
}
