.gt-img-card-holder {
    @include shadow(0px 0px 20px $shadow-clr);
    @include radius(8px);
    display: flex;
    padding: em(10);
    img,
    svg {
        margin: auto;
    }
    svg {
        width: 100%;
    }
    &-md {
        min-height: em(138);
        min-width: em(138);
        max-width: em(138);
        img {
            max-width: 70%;
        }
    }
    &-lg {
        min-height: em(214);
        min-width: em(138);
        max-width: em(214);
        img {
            max-width: 70%;
        }
    }
    & .no-image-available{
        max-width: 90%;
        height: em(80);
        width: em(100);
    }
}
