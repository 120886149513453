.vendor-management-table {
    min-width: 405px;
}
@media (max-width: 1200px) {
    .header-margin-container {
        margin-top: 4px;
    }
}
@media (max-width: 1024px) {
    .headerButtonResponsive {
        width: 200px;
    }
    .ad-finance-search-box-contanier {
        .header-search-responsiveness > input {
            height: 40px;
        }
    }
    .vendor-search-input-only {
        height: 100%;
    }
}
@media (max-width: 992px) {
    .vm-topheader-right {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .vm-topheader-right {
        flex-direction: column;
    }
    .vm-topheader-right > button {
        width: 100%;
        margin-bottom: 0.5rem;
    }
    .vm-topheader-right > .search-box-container {
        width: 100%;
        margin-left: 0px !important;
    }
    .vm-topheader-right
        > .search-box-container
        > div
        > .vendor-table-search-inner-class
        > input {
        width: 93%;
    }
}
@media (max-width: 992px) {
    .ad-finance-vendor-contanier {
        flex-direction: column;
    }
    .vendor-button-search-responsiveness {
        margin-top: 10px;
    }
}
@media (max-width: 678px) {
    .vendor-button-search-responsiveness {
        flex-direction: column-reverse;
    }
    .search-box-container {
        margin-left: 0;
        margin-bottom: 10px;
    }
    .headerButtonResponsive {
        width: 100%;
    }
    .ad-finance-search-box-contanier {
        .header-search-responsiveness > input {
            width: 92%;
        }
    }
}
