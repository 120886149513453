
.gt-profile-user-wid {
    background-color: transparent !important;
    border-radius: 50%;
    // border: 5px solid $white !important;
    border: 6px solid $gray-light-8;

    & img {
        object-fit: contain;
        border-radius: 50%;
    }
    .profile-pic {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .avatar-title {
        background-color: $gray-light-7 !important;
        color: $gray-dark-4 !important;
    }
}



