// .gt-sidebar.vertical-menu .gt-side-menu ul#side-menu li a {
//   padding-left: 15px !important;
// }
@media (max-width: 1200px) {
  #layout-wrapper {
    .navbar-brand-box {
      width: 200px;
    }
    .main-content {
      margin-left: 200px;
    }
  }
  .vertical-menu {
    width: 200px !important;
  }
  #sidebar-menu ul li a {
    padding: 0.625rem 0.5rem !important;
  }
 .vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a {
    padding-left: 25px !important;
  }
  .gt-sidebar.vertical-menu .gt-side-menu ul#side-menu li a {
    padding-left: 17px ;
  }
  .vertical-collpsed .main-content {
    margin-left: 70px !important;
  }
}
