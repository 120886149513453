@import "../../assets/scss/theme.scss";
.pagination-container {
    font-size: em(13);
}
.pagination-container ul li button {
    border: none;
    padding: em(6) em(14);
    border: 1px solid var(--bs-pagination-disabled-color);
    border-radius: 0 !important;
}
.page-link {
    background-color: transparent;
    color: $gray-dark;
}
.page-item.active .page-link {
    background-color: $btn-table-bg;
}

.page-item.disabled .page-link {
}

.page-link:hover {
    background-color: $gray-dark-2;
    color: $white;
}
