.gt-product-cart-item {
    padding-inline: em(35) em(80);
    &-checkbox {
        min-width: em(32);
        min-height: em(32);
        border: 1px solid $bdr-clr-3;
        margin-inline-end: em(30);
        cursor: pointer;
        @include radius(0px);
    }
    &-details {
        margin-bottom: em(13);
    }
    &-actions {
        margin-left: em(40);
        width: 100%;
    }
    &-sizes {
        margin-bottom: em(20);
    }
    &-desc {
        margin-top: em(11);
    }
    &.bdr-right {
        border-right: 1px solid $bdr-clr-4;
    }
}
