.gt-badge {
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
    font-weight: 300;
    &-pending {
        border-color: $badge-pending-clr;
        color: $badge-pending-clr;
    }
    &-verified {
        border-color: $badge-verified-clr;
        color: $badge-verified-clr;
    }
    &-delivered {
        border-color: $badge-delivered-clr;
        color: $badge-delivered-clr;
    }
    &-cancelled {
        border-color: $badge-cancelled-clr;
        color: $badge-cancelled-clr;
    }
    &-intransit {
        border-color: $badge-intransit-clr;
        color: $badge-intransit-clr;
    }
    &-mockups-pending {
        border-color: $badge-mockups-pending-clr;
        color: $badge-mockups-pending-clr;
    }
    &-mockups-ready {
        border-color: $badge-mockups-ready-clr;
        color: $badge-mockups-ready-clr;
    }
    &-production-pending {
        border-color: $badge-production-pending-clr;
        color: $badge-production-pending-clr;
    }
    &-production-ready {
        border-color: $badge-production-ready-clr;
        color: $badge-production-ready-clr;
    }
    &-completed {
        border-color: $badge-completed-clr;
        color: $badge-completed-clr;
    }
    &-active {
        border-color: $badge-active-clr;
        color: $badge-active-clr;
    }
    &-suspended {
        border-color: $badge-suspended-clr;
        color: $badge-suspended-clr;
    }
    &-invited {
        border-color: $badge-invited-clr;
        color: $badge-invited-clr;
    }
    &-rejected {
        border-color: $badge-rejected-clr;
        color: $badge-rejected-clr;
    }
    
    &-xl {
        padding-block: em(12);
    }
    &-lg {
        padding: em(8) em(50);
    }
    &-md {
        font-size: em(13);
        min-width: em(90);
        padding: em(6.5) em(21.5);
    }
    &-sm {
        font-size: em(11);
        min-width: em(57);
        padding: em(4) em(8);
    }
}
