.gt-product-sizes-wrap {
    .gt-product-size {
        @include shadow(0px 0px 6px $shadow-clr);
        @include radius(4px);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &-md {
            min-width: em(41);
            padding-inline: em(5);
            padding-block: em(8.5);
        }
        &-sm {
            min-width: em(32);
            padding: em(4);
        }
        &:not(:last-child) {
            margin-right: em(10);
        }
    }
}
