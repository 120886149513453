.gt-code-input {
    input {
        font-size: em(16);
        text-align: center;
        border: 1px solid $bdr-clr;
    }    
    &-bg-fill{
       input{
        background-color: $gray-light-6;
       }
    }
    &-md{
        input {
            padding: 0.5rem 1rem;
            @include radius(6.4px);
        width: em(76);
        height: em(42);
        &:not(:last-child) {
            margin-inline-end: em(24);
        }
    }
    }
    &-sm{
        input {
            padding: 0.5rem;
            @include radius(4px);
            width: em(37);
            height: em(38);
            &:not(:last-child) {
                margin-inline-end: em(14);
            }
        }
    }
}
