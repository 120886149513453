.ad-dashboard-table {
  min-width: 575px;
}
.verticalScrollDashboard {
  max-height: 50vh;
}

@media (max-width: 1440px) {
  .icon-img-container {
    width: 47px;
    height: 47px;
  }
}

@media (max-width: 1024px) {
  .analytics-box-title {
    width: 100%;
  }
  .icon-img-container {
    width: 43px;
    height: 43px;
  }
  .analytics-box-data {
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .analytics-box-title {
    font-size: 12px;
    font-weight: 500;
    width: 90px;
  }
  .icon-img-container {
    width: 40px;
    height: 40px;
  }
  .analytics-box-data {
    font-size: 40px;
  }
  .analytics-box-data-handler {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .analytics-box-bottom-btn {
    height: 33px;
    width: 120px;
  }
  .analytics-box-category-description {
    font-weight: 450;
    font-size: 14px;
  }
}

@media (max-width: 668px) {
  .analytics-box-data-handler {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .analytics-box-title {
    width: 50%;
  }
  .gt-btn-grad-primary {
    height: 35px;
    width: 120px !important;
    .gt-btn-lg {
      min-width: none !important;
    }
  }

  .analytics-box-category-description {
    font-weight: 450;
    font-size: 12px;
  }
  .analytics-box-category-title {
    font-weight: 450;
    font-size: 12px;
  }
  .inv-manag-topheader-btn{
    padding: 5px 25px !important;
    min-width: inherit !important;
    width: inherit !important;
  }
}
