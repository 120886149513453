.gt-product {
  max-width: em(214);
  width: 100%;
  border-width: 0;
  background-color: transparent;
  &-img {
    margin-block-start: em(30);
    margin-block-end: em(40);
  }
  &-qty {
    margin-block-start: em(13);
  }
  &-size-veriation-wrap {
    margin-right: em(10);
  }
  .gt-color-palette {
    position: relative;
    border: 3px solid $white;
    @include shadow(0px 0px 20px $shadow-clr);
    @include radius(50%);
    @include transition(all 0.5s ease-in-out);
    cursor: pointer;
    &-md {
      width: em(39);
      height: em(39);
      &:not(:last-child) {
        margin-right: em(20);
      }
    }
  }
}
