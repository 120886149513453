.gt-data-table {
    table {
        thead {
            th {
                border-top: 2px solid $gray-light-4;
                font-weight: 600;
                // color: black;
                font-size: em(13);
                padding: em(12);
                font-size: 13px;
    /* line-height: 20px; */
                color: $table-font-color;
            }
        }
        tbody {
            td {
                border: none;
                border-top: 1px solid $gray-light-4;
                vertical-align: middle;
                // font-size: em(13);
                font-size: 13px;
                padding: em(12);
                color: $table-font-color;
            }
        }
    }
    .view-details-icon {
        height: em(11);
        width: em(17);
        margin-right: em(2);
    }
    .view-details {
        font-size: 13px;
        color: $blue-2;
        text-decoration: underline;
    }
    &-head-style-2 {
        th {
            border: none !important;
            background-color: $table-head-bg;
        }
        table > :not(:first-child) {
            border-top: none !important;
        }
        tbody {
            tr {
                &:first-child {
                    > td {
                        border-top: none !important;
                    }
                }
            }
        }
    }
}
