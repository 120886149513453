.app-data-table {
   thead {
      color: #fff;

      th {
         border-top: 1px solid rgba(0, 0, 0, 0.1);
         border-bottom: 1px solid rgba(0, 0, 0, 0.1);

         // Commented these because not needed in the current project

         // &:first-child {
         //     border-top-left-radius: 4px;
         //     border-bottom-left-radius: 4px;
         // }
         // &:last-child {
         //     border-top-right-radius: 4px;
         //     border-bottom-right-radius: 4px;
         // }
         font-weight: normal;
         .table-filter-dropdown-menu-con {
            .dropdown-menu {
               .dropdown-item.active,
               .dropdown-item:active {
                  background: #2795ae;
                  color: white;
               }
            }
         }
      }
   }
   tbody {
      // Commented these because not needed in the current project
      // This makes the rows bigger

      // tr {
      //     transition: all 0.3s;
      //     &:hover {
      //         box-shadow: -7px 0px 30px #00000029;
      //     }
      // }

      tr * {
         background-color: transparent;
         color: #40393a;
         vertical-align: middle;
      }
      .colomn-width-limit {
         max-width: 200px;
         white-space: break-spaces;
         word-break: break-all;
      }
      .colomn-width-limit-anchor-tag {
         width: 200px;
         white-space: break-spaces;
         word-break: break-all;
         display: block;
      }
   }
}
.data-table-head {
   display: flex;
   align-items: center;
   flex-direction: row;

   &.sortable {
      user-select: none;
      cursor: pointer;
   }

   & i {
      margin-left: 1px;
      font-size: 15px;

      &.bx-sort {
         opacity: 0.5;
      }
   }

   & .filter-value {
      font-size: 10px;
      margin-left: 3px;
      font-style: italic;
      color: black;
   }
}

.cursor-pointer {
   cursor: pointer;
}

.table-min-height {
   min-height: 350px;
}

.table-loader-container {
   height: 340px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.column-filter-menu {
   font-size: 20px;
   cursor: pointer;
}

.column-filter-item {
   padding-top: 6px;
   padding-bottom: 6px;
   label,
   input {
      cursor: pointer;
   }
}

.column-filter-item:not(:last-child) {
   border-bottom: 1px solid #eee;
}

span.column-title {
   // font-weight: 700;
   color: black;
}
