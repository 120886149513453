.preview-container {
   width: 150px;
   height: 95px;
   position: relative;
   padding: 5px;

   .cross-button {
      cursor: pointer;
      width: 15px;
      height: 15px;
      background-color: white;
      position: absolute;
      right: 10px;
      top: 10px;
      border-radius: 50px;
   }

   .preview-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #cecece;
   }
}

.rounded-img-container {
   border-radius: 6px !important;
}

/* Skeleton Effect */
.skeleton-image-gallery {
   display: inline-block;
   background: linear-gradient(90deg, #e0e0e0 25%, #f2f2f2 50%, #e0e0e0 75%);
   background-size: 200% 100%;
   animation: skeleton-loading 1.5s infinite ease-in-out;
   border-radius: 4px;
   color: transparent;
}

/* Skeleton Animation */
@keyframes skeleton-loading {
   0% {
      background-position: 200% 0;
   }
   100% {
      background-position: -200% 0;
   }
}
