.ad-location-table {
  min-width: 824px;
}

@media (max-width: 992px) {
  .location-flexResponsiveInner {
    width: 100%;
  }
  .location-flex-responsiveness {
    flex-direction: column;
    justify-content: left;
    align-items: start !important;
  }
  .admin-location-search-box-container {
    // margin-right: 5px;
    height: 40px;
    margin-bottom: 0;
  }
  .location-searchResponsiveness {
    width: 240px;
  }
  .location-header-btn-responsive {
    height: 40px;
    font-size: 13px !important;
    width: 200px;
  }
  .admin-location-search-box-container {
    .location-searchResponsiveness > input {
      height: 40px;
      width: 200px;
    }
  }
  .flexResponsiveInner {
    width: 100%;
  }
}

@media (max-width: 720px) {
  .location-flexResponsiveInner {
    align-items: center;
  }
  .location-flexResponsiveInner {
    display: flex;
    flex-direction: column-reverse;
  }
  .admin-location-search-box-container,
  .location-searchResponsiveness {
    margin-top: 0.5rem;
    width: 100%;
  }

  .admin-location-search-box-container {
    .location-searchResponsiveness > input {
      width: 93% !important;
    }
  }
  .ad-location-header-btn-responsive {
    width: 100% !important;
    margin-top: 1rem;
  }
}
