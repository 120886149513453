.place-order-image-con {
  width: 205px;
  height: 50px;
  border: 1px solid #296F9E;
  border-radius: 5px;
  cursor: pointer;
}

.place-order-image-con > span{
  font-size: 18px;
  letter-spacing: 0px;
  color: #296F9E;
}
.img-upload-name{
  max-width: 100%;
  font-size: 13px;
}
.file-upload-error{
  font-size: 13px;
}