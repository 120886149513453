.gt-product-simple {
    max-width: em(192);
    &-title {
        margin-block-start: em(51);
        margin-block-end: em(40);
    }
    &-img {
        margin-block-start: em(10);
        margin-block-end: em(15);
    }
    &-desc {
        margin-block-start: em(20);
    }
}
