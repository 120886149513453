.form-check-input-custom {
  width: 27px;
  height: 27px;
  border: 2px solid #74788d;
}
.form-check-input-custom:checked {
  background-color: transparent;
  border: 2px solid #74788d;
}
.signin-remember-box {
  width: 15px;
  height: 15px;
  border: 1.5px solid #000000;
}
.signin-remember-box:checked[type="checkbox"] {
  background-image: url("../../../images/signin/tick.png");
  background-color: transparent;
  border-radius: 0.25em;
  border-color: #000000;
}
.signin-remember-box:focus {
  border-color: #000000;
}
.form-check-input-custom:checked[type="checkbox"] {
  background-image: url("../../../images/signin/Vector.png");
  background-size: 85% 85%;
}
.form-check-input-custom:focus[type="checkbox"] {
  background-color: transparent;
  border: 2px solid #74788d;
}
