.gt-product-simple-two-col {
    padding-inline: em(20) em(50);
    &-title {
        margin-block-end: em(25);
    }
    &-img {
        margin-block-start: em(13);
        margin-block-end: em(20);
    }
    &-left {
        width: 100%;
    }
    &-right {
        margin-inline-start: em(55);
        width: 100%;
    }
    &.bdr-right {
        border-right: 1px solid $bdr-clr-4;
    }
}
